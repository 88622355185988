import { defineComponent, defineAsyncComponent, reactive, computed } from 'vue';
import { Selector } from '@hems/component';
import { getPrivacyPolicy } from '@hems/component/src/termAndCondition';
import { useLanguage } from '@hems/util';
const termsVerAU = process.env.VUE_APP_TERMS_VER_AU || '';
const PrivacyAUEN = defineAsyncComponent(() => getPrivacyPolicy('AU', termsVerAU, 'en'));
export default defineComponent({
    name: 'AUTermsAndConditionPage',
    components: {
        Selector,
        PrivacyAUEN,
    },
    setup() {
        const { languageOptionsForManagement } = useLanguage();
        const state = reactive({
            region: 'AU',
            lang: 'en',
            langOptions: languageOptionsForManagement,
        });
        const componentName = computed(() => `Privacy${state.region.toUpperCase()}${state.lang.toUpperCase()}`);
        return {
            state,
            componentName,
        };
    },
});
