import { defineComponent, defineAsyncComponent, reactive, computed } from 'vue';
import { Selector } from '@hems/component';
import { getPrivacyPolicy } from '@hems/component/src/termAndCondition';
import { useLanguage } from '@hems/util/src/composable/language';
const termsVerNZ = process.env.VUE_APP_TERMS_VER_NZ || '';
const PrivacyNZEN = defineAsyncComponent(() => getPrivacyPolicy('NZ', termsVerNZ, 'en'));
export default defineComponent({
    name: 'NZTermsAndConditionPage',
    components: {
        Selector,
        PrivacyNZEN,
    },
    setup() {
        const { languageOptionsForManagement } = useLanguage();
        const state = reactive({
            region: 'NZ',
            lang: 'en',
            langOptions: languageOptionsForManagement,
        });
        const componentName = computed(() => `Privacy${state.region.toUpperCase()}${state.lang.toUpperCase()}`);
        return {
            state,
            componentName,
        };
    },
});
