import { defineComponent, defineAsyncComponent, reactive, computed } from 'vue';
import { Selector } from '@hems/component';
import { getPrivacyPolicy } from '@hems/component/src/termAndCondition';
import { LANGUAGE, USLanguageOptions } from '@hems/util/src/constant';
const termsVerUS = process.env.VUE_APP_TERMS_VER_US || '';
const PrivacyUSEN = defineAsyncComponent(() => getPrivacyPolicy('US', termsVerUS, 'en'));
export default defineComponent({
    name: 'USTermsAndConditionPage',
    components: {
        Selector,
        PrivacyUSEN,
    },
    setup() {
        const state = reactive({
            region: 'US',
            lang: 'en',
            langOptions: USLanguageOptions.filter((item) => item.value === LANGUAGE.EN),
        });
        const componentName = computed(() => `Privacy${state.region.toUpperCase()}${state.lang.toUpperCase()}`);
        return {
            state,
            componentName,
        };
    },
});
